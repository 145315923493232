import React from "react"

import Kontakt from "../Kontakt/Kontakt"

const Footer = () => {
  return (
    <div>
      <Kontakt />
    </div>
  )
}

export default Footer
