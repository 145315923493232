export default [
  {
    key: "2",
    text: "vyrábíme",
    url: "#vyrabime",
    /*  subLinks: [
      {
        page: "vyrabime",
        text: "Pergoly, Altany",
        url: `/vyrabime/pergolyAltany`,
      },
      {
        page: "vyrabime",
        text: "Dřevostavby",
        url: `/vyrabime/drevostavby`,
      },
      {
        page: "vyrabime",
        text: "Dřevěné štíty a fasády",
        url: `/vyrabime/stityFasady`,
      },
      {
        page: "vyrabime",
        text: "Krovy",
        url: `/vyrabime/krovy`,
      },
      {
        page: "vyrabime",
        text: "Garážová stání",
        url: `/vyrabime//garazovaStani`,
      },
      {
        page: "vyrabime",
        text: "Interiér Exteriér",
        url: `/vyrabime/interierExterier`,
      },
      {
        page: "vyrabime",
        text: "Dřevníky",
        url: `/vyrabime/drevnik`,
      },
    ], */
  },
  {
    key: "4",
    text: "typové stavby",
    url: "#tyoveStavby",
    badge: "nové",
  },
  {
    key: "3",
    text: "kontakt",
    url: "#kontakt",
  },
]
